import { buildProperty } from '@camberi/firecms'

export type ProductInterface = {
  publish: boolean
  order: number
  category: string
  title: string
  img: string
  description: string
}

export type inputType = {
  name: string
  path: string
  group: string
}

export const getProductProperties = (params: inputType) => {
  const gr = params.group.charAt(0).toUpperCase() + params.group.slice(1)

  const ProductProperties: any = {
    name: params.name,
    singularName: params.name,
    description: `${gr} - ${params.name}`,
    path: `${params.group}_${params.path}`,
    group: gr,
    initialSort: ['order', 'asc'],
    defaultSize: 'xs',

    permissions: ({ authController }: any) => ({
      edit: true,
      create: true,
      delete: true
    }),

    properties: {
      publish: {
        name: 'Publiceren?',
        validation: { required: true },
        dataType: 'boolean',
        defaultValue: true
      },
      order: {
        name: 'Volgorde',
        validation: { required: true },
        dataType: 'number'
      },

      img: buildProperty({
        name: 'Afbeelding',
        dataType: 'string',
        storage: {
          storeUrl: true,
          storagePath: `images/${params.group}/${params.path}`,
          acceptedFiles: ['image/*']
        }
      }),
      category: {
        name: 'Categorie',
        validation: { required: false },
        dataType: 'string'
      },
      title: {
        name: 'Titel',
        validation: { required: false },
        dataType: 'string'
      },

      description: {
        name: 'Omschrijving',
        validation: { required: false },
        dataType: 'string',
        markdown: true
      }
    }
  }

  return ProductProperties
}
