import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty
} from '@camberi/firecms'

import { inputType, PageInterface, getPageProperties } from './PageInterface'

/*
const productCallbacks = buildEntityCallbacks({
  onPreSave: ({ collection, path, entityId, values, status }) => {
    values.title_nl = `${values.title_nl?.toLowerCase()}`
    values.title_en = `${values.title_en?.toLowerCase()}`
    return values
  }
})
 */

const thiagoPageInput: inputType = {
  name: 'Pagina',
  group: 'thiago',
  path: 'pages'
}
const thiagoPage = buildCollection<PageInterface>(
  getPageProperties(thiagoPageInput)
)

const guilhermoPageInput: inputType = {
  name: 'Pagina',
  group: 'guilhermo',
  path: 'pages'
}
const guilhermoPage = buildCollection<PageInterface>(
  getPageProperties(guilhermoPageInput)
)

export { thiagoPage, guilhermoPage }
